import { FormattedMessage } from 'react-intl';

import { IRequestLimitTextProps } from '@app/components-new/RequestLimitText/types';

export const LimitText: Record<IRequestLimitTextProps['status'], React.ReactNode> = {
  'Available': <FormattedMessage id="pages.buyersPage.limit.totalLimit" />,
  'RequestingLimit': <FormattedMessage id="buyersPage.requestedLimit" />,
  'FinancingDenied': '',
  'Unknown': '',
  'UnderReview': <FormattedMessage id="buyersPage.requestedLimit" />,
  'Overdue': <FormattedMessage id="pages.buyersPage.limit.totalLimit" />,
};
