import { type FC } from 'react';

import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { FormPhoneField, FormTextField, Stack, Typography } from '@stenngroup/ui-kit';

import { IContactForm } from '../types';

import { MuiPhoneData } from '@app/types/muiPhoneData';

interface IProps {
  disabled?: boolean;
}

export const SignatoryForm: FC<IProps> = ({ disabled }) => {
  const form = useFormContext<IContactForm>();

  const { control, setValue, trigger } = form;

  return (
    <form>
      <Stack spacing={3}>
        <Typography.TextMd.Medium>
          <FormattedMessage id="createDeal.addTradePartnerSignatory.form.description" />
        </Typography.TextMd.Medium>

        <Stack spacing={2}>
          <FormTextField
            name="firstName"
            control={control}
            label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.firstNameField" />}
            disabled={disabled}
          />
          <FormTextField
            name="lastName"
            control={control}
            label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.lastNameField" />}
            disabled={disabled}
          />
          <FormTextField
            name="position"
            control={control}
            label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.positionField" />}
            disabled={disabled}
          />
        </Stack>
        <Stack spacing={2}>
          <Typography.TextMd.Medium>
            <FormattedMessage id="createDeal.addTradePartnerSignatory.form.emailField.description" />
          </Typography.TextMd.Medium>
          <Stack spacing={2}>
            <FormTextField
              name="email"
              control={control}
              label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.emailField" />}
              disabled={disabled}
            />

            <FormPhoneField
              name="internalPhoneNumber"
              control={control}
              label={<FormattedMessage id="constants.universalMessages.phoneNumberOptional" />}
              disabled={disabled}
              onChange={(_: string, phoneData: MuiPhoneData) => {
                setValue('phoneNumber', phoneData.nationalNumber ? phoneData.numberValue : '');
                trigger('phoneNumber');
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};
