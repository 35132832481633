export type Nullable<T> = T | null;
export type AnyObject = Record<string, any>;
export type ValueOf<T> = T[keyof T];
export type SimpleFunction = () => void;
/**
 *  Make required target keys
 */
export type UnionOfArray<A extends ReadonlyArray<string>> = A[number];

/**
 * alias for Object.keys with persisted keys types
 */
export const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;
