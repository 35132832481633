import { IntlFormatters } from 'react-intl';

import * as yup from 'yup';

import { baseAuthorizedSignatorySchema } from '@app/components-new/FlowTransitions/screens/AddCompanySignatory/Form/baseSchema';
import { IContactForm } from '../../types';

export const anotherPersonSignatorySchema = (formatMessage: IntlFormatters['formatMessage']) =>
  baseAuthorizedSignatorySchema(formatMessage).shape<Record<keyof Pick<IContactForm, 'checkbox'>, yup.AnySchema>>({
    checkbox: yup.boolean().oneOf([true, false], formatMessage({ id: 'constants.frontendErrors.confirmCheckbox' })),
  });
