export enum EContactStatus {
  NotInvited = 'NotInvited',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Active = 'Active',
  Requesting = 'Requesting',
  Declined = 'Declined',
  Approved = 'Approved',
  AuthorizedSignatory = 'AuthorizedSignatory',
}

export interface ICompanyContact {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phoneNumber?: string;
  email: string;
  position: string;
  effectiveDate: string;
  status: EContactStatus;
  editable: boolean;
  requestId: string | null;
}

export interface ICompanyBankDetails {
  id: string;
  companyId: string;
  bankDetailStatus: string;
  beneficiaryCountryCode: string;
  isCanEdit: boolean;
  currencyCode: number;
  beneficiaryName: string;
  bankName: string;
  swift: string;
  beneficiaryAccountNumber: string;
  beneficiaryAddress1: string;
  beneficiaryAddress2: string;
  currency: string;
  isDefault: boolean;
}

export interface IPersonalSpaceProps {}
