import { FC, useMemo } from 'react';

import { FormattedDate, FormattedMessage } from 'react-intl';
import { QueryStatus, useQuery } from 'react-query';

import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { EFlowStep } from '@app/components-new/FlowTransitions/context/types';
import { ListContainer } from '@app/components-new/PageListContainer/ListContainer';
import { StepContainer } from '@app/components-new/StepContainer';
import { TEST_IDS } from '@app/core/constants/testIds';
import { ERequestKey } from '@app/types/request';
import { formatCurrency } from '@app/utils/formatCurrency';
import { Stack } from '@mui/material';
import { Button, Typography } from '@stenngroup/ui-kit';
import { match } from 'ts-pattern';

import { InvoiceDetails } from './InvoiceDetails';
import { InvoicesListSkeleton } from './InvoicesListSkeleton';
import { getTradeRelationsInvoicesByIdRequest } from './queries';

interface IInvoicesListProps {}

export const InvoicesList: FC<IInvoicesListProps> = () => {
  const { handleNextStep, goTo, getFlowState } = useFlowTransitionsContext();
  const { trId } = getFlowState();
  const { data, status, refetch, isRefetching } = useQuery(
    [ERequestKey.TradeRelationInvoices, trId],
    () => (trId ? getTradeRelationsInvoicesByIdRequest(trId) : null),
    { enabled: !!trId, cacheTime: 0 }
  );
  const handleNext = () => {
    handleNextStep();
  };

  const queryStatus = match<QueryStatus, QueryStatus>(status)
    .with('loading', () => 'loading')
    .with('error', () => 'error')
    .with('success', () => (isRefetching ? 'loading' : 'success'))
    .otherwise(() => 'idle');

  const addAnotherInvoiceParams = useMemo(() => ({ hasInvoices: !!data?.length }), [data]);

  return (
    <StepContainer
      title={<FormattedMessage id="createDeal.invoicesList.title" />}
      onNext={handleNext}
      isNextDisabled={!data || data.length === 0}
    >
      <Stack gap={2} data-testid={TEST_IDS.invoicesList}>
        <ListContainer
          items={data ?? undefined}
          status={queryStatus}
          emptyMessageId="createDeal.invoicesList.emptyList"
          Row={(invoice) => (
            <InvoiceDetails
              key={invoice.id}
              trId={trId}
              invoiceId={invoice.id}
              invoiceNumber={invoice.number}
              invoiceValue={formatCurrency(invoice.invoiceFaceValue, {
                currency: invoice.currency,
              })}
              onSave={() => refetch()}
              dueDate={<FormattedDate value={invoice.dueDate} dateStyle="medium" />}
            />
          )}
          LoadingIndicator={<InvoicesListSkeleton />}
        />
        <Button
          size="medium"
          variant="tertiary"
          disabled={queryStatus === 'loading'}
          onClick={() => goTo(EFlowStep.UPLOAD_INVOICE, 'backward', { stepState: addAnotherInvoiceParams })}
        >
          <FormattedMessage id="constants.universalMessages.addAnotherInvoice" />
        </Button>
        <Typography.Body2 color={(t) => t.palette.text.secondary}>
          <FormattedMessage id="createDeal.invoicesList.helpMessage" />
        </Typography.Body2>
      </Stack>
    </StepContainer>
  );
};
