import {
  EDealTradeRole,
  EFlowStep,
  EFlowType,
  IFlowState,
  TTransitions,
} from '@app/components-new/FlowTransitions/context/types';

export const transitionsMap: TTransitions = {
  [EFlowStep.TRADE_ROLE]: {
    step: EFlowStep.TRADE_ROLE,
    next: (state: IFlowState) => {
      if (state.flowType === EFlowType.AddTradePartner) {
        return EFlowStep.SEARCH_TRADE_PARTNER;
      }
      return EFlowStep.SELECT_TRADE_PARTNER;
    },
  },
  [EFlowStep.SEARCH_TRADE_PARTNER]: {
    step: EFlowStep.SEARCH_TRADE_PARTNER,
    next: () => {
      return EFlowStep.SELECT_NEW_TRADE_PARTNER;
    },
  },
  [EFlowStep.SELECT_NEW_TRADE_PARTNER]: {
    step: EFlowStep.SELECT_NEW_TRADE_PARTNER,
    next: (state: IFlowState) => {
      if (state.tradeRole === EDealTradeRole.Supplier) {
        return EFlowStep.REQUEST_LIMIT;
      }
      if (state.flowType === EFlowType.AddTradePartner) {
        return EFlowStep.TRADE_PARTNER_CREATED;
      }
      return EFlowStep.SELECT_TRADE_PARTNER;
    },
  },
  [EFlowStep.REQUEST_LIMIT]: {
    step: EFlowStep.REQUEST_LIMIT,
    next: (state: IFlowState) => {
      if (state.flowType === EFlowType.AddTradePartner) {
        return EFlowStep.TRADE_PARTNER_CREATED;
      }
      return EFlowStep.SELECT_TRADE_PARTNER;
    },
  },
  [EFlowStep.TRADE_PARTNER_CREATED]: {
    step: EFlowStep.TRADE_PARTNER_CREATED,
    next: () => {
      return null;
    },
  },
  [EFlowStep.SELECT_TRADE_PARTNER]: {
    step: EFlowStep.SELECT_TRADE_PARTNER,
    next: (state: IFlowState) => {
      if (state.hasDraftInvoice) {
        return EFlowStep.INVOICES_LIST;
      }
      return EFlowStep.UPLOAD_INVOICE;
    },
  },
  [EFlowStep.UPLOAD_INVOICE]: {
    step: EFlowStep.UPLOAD_INVOICE,
    next: () => {
      return EFlowStep.INVOICES_LIST;
    },
  },
  [EFlowStep.INVOICES_LIST]: {
    step: EFlowStep.INVOICES_LIST,
    next: (state: IFlowState) => {
      if (!state.myCompanyHasSigner) return EFlowStep.ADD_COMPANY_SIGNATORY;
      if (!state.counterpartyCompanyHasSigner) return EFlowStep.ADD_TRADE_PARTNER_SIGNATORY;
      if (!state.supplierHasBankDetails) return EFlowStep.ADD_BANK_DETAILS;

      return EFlowStep.REVIEW_DEAL;
    },
  },
  [EFlowStep.ADD_COMPANY_SIGNATORY]: {
    step: EFlowStep.ADD_COMPANY_SIGNATORY,
    next: (flowState: IFlowState) => {
      if (!flowState.counterpartyCompanyHasSigner) return EFlowStep.ADD_TRADE_PARTNER_SIGNATORY;
      if (!flowState.supplierHasBankDetails) return EFlowStep.ADD_BANK_DETAILS;

      return EFlowStep.REVIEW_DEAL;
    },
  },
  [EFlowStep.ADD_TRADE_PARTNER_SIGNATORY]: {
    step: EFlowStep.ADD_TRADE_PARTNER_SIGNATORY,
    next: (flowState: IFlowState) => {
      if (!flowState.supplierHasBankDetails) return EFlowStep.ADD_BANK_DETAILS;

      return EFlowStep.REVIEW_DEAL;
    },
  },
  [EFlowStep.ADD_BANK_DETAILS]: {
    step: EFlowStep.ADD_BANK_DETAILS,
    next: () => {
      return EFlowStep.REVIEW_DEAL;
    },
  },
  [EFlowStep.REVIEW_DEAL]: {
    step: EFlowStep.REVIEW_DEAL,
    next: () => {
      return EFlowStep.DEAL_SUBMITTED;
    },
  },
  [EFlowStep.DEAL_SUBMITTED]: {
    step: EFlowStep.DEAL_SUBMITTED,
    next: () => {
      return null;
    },
  },
  [EFlowStep.INVITE_TRADE_PARTNER]: {
    step: EFlowStep.INVITE_TRADE_PARTNER,
    next: () => {
      return EFlowStep.TRADE_PARTNER_INVITED;
    },
  },
  [EFlowStep.TRADE_PARTNER_INVITED]: {
    step: EFlowStep.TRADE_PARTNER_INVITED,
    next: () => {
      return null;
    },
  },
} as const;
