import { FC, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { EFlowStep } from '@app/components-new/FlowTransitions/context/types';
import { ConfirmationDialogContent } from '@app/components-new/FlowTransitions/screens/UploadInvoice/ConfirmationDialogContent';
import { UploadInvoiceForm } from '@app/components-new/Invoice/UploadInvoiceForm';
import { baseInvoiceSchema } from '@app/components-new/Invoice/UploadInvoiceForm/baseSchema';
import { formatToPayload } from '@app/components-new/Invoice/UploadInvoiceForm/helpers/formatToPayload';
import { getFormData } from '@app/components-new/Invoice/UploadInvoiceForm/helpers/getFormData';
import { IUploadInvoiceForm, IUploadInvoiceFormOutput } from '@app/components-new/Invoice/UploadInvoiceForm/types';
import { StepContainer } from '@app/components-new/StepContainer';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { EDealType, TEST_IDS } from '@app/core/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowForward } from '@mui/icons-material';
import { Card, Stack } from '@mui/material';
import { Button, Dialog } from '@stenngroup/ui-kit';

interface IUploadInvoiceProps {}

export const UploadInvoice: FC<IUploadInvoiceProps> = () => {
  const { formatMessage } = useIntl();
  const [isOpenDiscardChangesDialog, setIsOpenDiscardChangesDialog] = useState<boolean>(false);

  const form = useForm<IUploadInvoiceForm>({
    mode: 'onSubmit',
    defaultValues: getFormData(),
    resolver: yupResolver(baseInvoiceSchema(formatMessage)),
  });

  const { handleNextStep, getFlowState, getStateByStep } = useFlowTransitionsContext();
  const stepState = getStateByStep<{ hasInvoices: boolean } | null>(EFlowStep.INVOICES_LIST);
  const { watch } = form;

  const isFormFilled =
    !!watch('currency') &&
    !!watch('faceValueNationalCurrency') &&
    !!watch('number') &&
    watch('dealType') === EDealType.Goods &&
    !!watch('dueDate');

  const { trId } = getFlowState();

  const hasInvoiceDocuments = !!watch('invoiceDocument');
  const hasTransportDocuments = !!watch('transportDocument');

  const isCategorySelected = watch('isCommodity') || watch('isPerishable') || watch('isUnknownCategory');

  const isSubmitDisabled = !hasInvoiceDocuments || !hasTransportDocuments || !isCategorySelected || !isFormFilled;

  const httpClient = useHttpClient();

  const createInvoiceMutation = useMutation({
    mutationFn: (data: IUploadInvoiceFormOutput) =>
      httpClient(apiRoutes.InvoiceCreateInvoice, 'post', {
        data: formatToPayload({ data, tradeRelationId: trId }),
      }),
    onSuccess: () => {
      handleNextStep();
    },
  });

  const handleNext = form.handleSubmit((data: IUploadInvoiceForm) => {
    createInvoiceMutation.mutate(data as IUploadInvoiceFormOutput);
  });
  return (
    <StepContainer
      title={<FormattedMessage id="createDeal.uploadInvoices" />}
      actions={
        <Stack gap={1} direction="row">
          {stepState?.hasInvoices && (
            <Button
              variant="tertiary"
              onClick={() => setIsOpenDiscardChangesDialog(true)}
              data-testid={TEST_IDS.discardChangesBtn}
            >
              <FormattedMessage id="constants.universalMessages.discardInvoice" />
            </Button>
          )}
          <Button
            variant="primary"
            onClick={handleNext}
            endIcon={<ArrowForward />}
            isLoading={createInvoiceMutation.isLoading}
            disabled={isSubmitDisabled || createInvoiceMutation.isLoading}
            data-testid={TEST_IDS.nextButton}
          >
            <FormattedMessage id="constants.universalMessages.saveInvoice" />
          </Button>
        </Stack>
      }
    >
      <Card
        sx={(theme) => ({
          padding: 2,
          boxShadow: 'none',
          border: `1px solid ${theme.palette.grey['200']}`,
          borderRadius: theme.shape.borderRadius / 2,
        })}
      >
        <FormProvider {...form}>
          <UploadInvoiceForm tradeRelationId={trId} />
        </FormProvider>
      </Card>
      <Dialog
        open={isOpenDiscardChangesDialog}
        onClose={() => setIsOpenDiscardChangesDialog(false)}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '16px',
          },
        }}
      >
        <ConfirmationDialogContent
          onCancel={() => setIsOpenDiscardChangesDialog(false)}
          onContinue={() => {
            setIsOpenDiscardChangesDialog(false);
            handleNextStep();
          }}
        />
      </Dialog>
    </StepContainer>
  );
};
