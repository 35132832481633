import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { ERole, IRoleSelectionForm } from '@app/components-new/RoleSelectionForm/types';
import { TEST_IDS } from '@app/core/constants/testIds';
import { Stack } from '@mui/material';
import { ChoiceBox } from '@stenngroup/ui-kit';

export const RoleSelectionForm: FC<IRoleSelectionForm> = ({ form, isBuyerRoleDisabled }) => {
  const { watch, setValue } = form;
  return (
    <Stack gap={1.5} width="100%">
      <ChoiceBox
        label={<FormattedMessage id="constants.universalMessages.supplier" />}
        text={<FormattedMessage id="pages.roleSelection.supplierDescription" />}
        type="radio"
        name="type"
        value={ERole.Supplier}
        checked={watch('type') === ERole.Supplier}
        onChange={() => setValue('type', ERole.Supplier)}
        fullWidth
        data-testid={TEST_IDS.roleSelectCardSupplierRadio}
      />
      <ChoiceBox
        label={<FormattedMessage id="constants.universalMessages.buyer" />}
        text={<FormattedMessage id="pages.roleSelection.buyerDescription" />}
        type="radio"
        name="type"
        value={ERole.Buyer}
        checked={watch('type') === ERole.Buyer}
        onChange={() => setValue('type', ERole.Buyer)}
        fullWidth
        data-testid={TEST_IDS.roleSelectCardBuyerRadio}
        disabled={isBuyerRoleDisabled}
      />
    </Stack>
  );
};
