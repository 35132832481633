import { UseFormReturn } from 'react-hook-form';

export const enum ERole {
  Supplier = 'Supplier',
  Buyer = 'Buyer',
}

export interface IForm {
  type: ERole;
}

export interface IRoleSelectionForm {
  form: UseFormReturn<IForm>;
  isBuyerRoleDisabled?: boolean;
}
