import { FC, useEffect } from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { DevFeatureToggleContainer } from '@app/components-new/DevFeatureToggleContainer';
import { FlowDrawerContainer } from '@app/components-new/FlowDrawerContainer';
import { FlowTransitionsContextProvider } from '@app/components-new/FlowTransitions/context/provider/FlowTransitionsContextProvider';
import { CriticalErrorLayout } from '@app/components/CriticalErrorLayout';
import ToastManager from '@app/components/Toast/Manager';
import { AuthProviderWrapper } from '@app/context/AuthProviderWrapper';
import { LangContextProvider } from '@app/context/LangContext/LangContextProvider';
import { useLangContext } from '@app/context/LangContext/hooks/useLangContext';
import NotificationContext from '@app/context/NotificationContext';
import { QueryClientProviderWrapper } from '@app/context/QueryClientProvider';
import { HubSpotAnalyticsListener } from '@app/core/analytics/HubSpotAnalyticsListener';
import { Auth0Connection } from '@app/core/authentication';
import { store } from '@app/core/global-store';
import { HttpClientError } from '@app/core/httpClient/HttpClientError';
import { IntlWrapperV2 } from '@app/core/internationalization';
import { useSetAnalitycsIdentity } from '@app/hooks/useSetAnalyticsIdentity';
import { Routes } from '@app/routes';
import SignalrProvider from '@app/signalr/SignalrProvider';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { CircularProgress, Stack } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { useAuth } from '@stenngroup/auth0-sdk';
import { ResetStylesNew } from '@stenngroup/theme';
import { ConfigProvider, ToastManager as StennToastManager } from '@stenngroup/ui-kit';

import { useGetProfile } from '@app/api/hooks/useGetProfile';
import { AcceptPoliciesContainer } from './components/AcceptPoliciesContainer';
import { CookiesConsentWrapper } from './components/CookiesConsentWrapper';

const AppInner: FC = () => {
  const { isLoading: isAuthLoading, isAuthenticated, userInfo, getAccessTokenSilently } = useAuth();
  const { data: profile, isError, error } = useGetProfile();
  useSetAnalitycsIdentity();
  useEffect(() => {
    Auth0Connection.setAccessTokenGetter(getAccessTokenSilently);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAuthLoading || (isAuthenticated && !userInfo)) {
    return (
      <Stack flexGrow={1} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  const errorStatus = (error as HttpClientError)?.status;
  if (isError && errorStatus !== 403) {
    return <CriticalErrorLayout errorCode={errorStatus} />;
  }

  return (
    <>
      <Routes />
      {isAuthenticated && <AcceptPoliciesContainer />}
      <CookiesConsentWrapper userId={profile?.userId} />
    </>
  );
};

const OuterApp: FC = () => {
  const { langId } = useLangContext();
  return (
    // TODO REMOVE REDUX PROVIDER AFTER MIGRATION
    <Provider store={store}>
      <ConfigProvider locale={langId} colorMode="light">
        <IntlWrapperV2>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ResetStylesNew />
            <ToastManager>
              <StennToastManager>
                <QueryClientProviderWrapper>
                  <NotificationContext.Provider value={{ isSignalrConnected: false }}>
                    <BrowserRouter>
                      <HubSpotAnalyticsListener>
                        <AuthProviderWrapper>
                          <ErrorBoundary fallback={<CriticalErrorLayout />}>
                            <SignalrProvider>
                              {/* <ToggleDevModeContainer /> */}
                              <DevFeatureToggleContainer />
                              <FlowTransitionsContextProvider>
                                <FlowDrawerContainer />
                                <Stack height="100%" minWidth={320}>
                                  <AppInner />
                                </Stack>
                              </FlowTransitionsContextProvider>
                            </SignalrProvider>
                          </ErrorBoundary>
                        </AuthProviderWrapper>
                      </HubSpotAnalyticsListener>
                    </BrowserRouter>
                  </NotificationContext.Provider>
                </QueryClientProviderWrapper>
              </StennToastManager>
            </ToastManager>
          </LocalizationProvider>
        </IntlWrapperV2>
      </ConfigProvider>
    </Provider>
  );
};

export const NovaApp: FC = () => {
  return (
    <LangContextProvider>
      <OuterApp />
    </LangContextProvider>
  );
};
