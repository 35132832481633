import { MOBILE_SMALL_SCREEN } from '@app/core/constants';
import { FormControlLabel, RadioGroup } from '@mui/material';
import styled from 'styled-components';

export const AnotherPersonInviteEmailRadioGroup = styled(RadioGroup)`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(4.7)};

  ${({ theme }) => theme.breakpoints.down(MOBILE_SMALL_SCREEN)} {
    column-gap: ${({ theme }) => theme.spacing(0)};
    row-gap: ${({ theme }) => theme.spacing(1)};
    margin-left: ${({ theme }) => `-${theme.spacing(1.4)}`};
  }
`;

export const RadioFormLabel = styled(FormControlLabel)`
  color: ${({ theme }) =>
    // @ts-expect-error fix
    theme.palette.black};
  margin: ${({ theme }) => theme.spacing(0)};
`;

export const AuthorisedSignatoryRadio = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const AuthorisedSignatoryRadioTitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
