import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { FlowTransitions } from '@app/components-new/FlowTransitions/FlowTransitions';
import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { EFlowType } from '@app/components-new/FlowTransitions/context/types';
import { TEST_IDS } from '@app/core/constants/testIds';
import { DrawerHeader, DrawerV2, Typography } from '@stenngroup/ui-kit';

export const FlowDrawerContainer: FC = () => {
  const { isOpen, close, getFlowState } = useFlowTransitionsContext();
  const { flowType } = getFlowState();
  return (
    <DrawerV2
      open={isOpen}
      onClose={close}
      sx={{
        '& .MuiDrawer-paper': {
          overflowX: 'hidden',
          minWidth: 580,
        },
      }}
    >
      <>
        <DrawerHeader onClose={close}>
          <Typography.H5 data-testid={TEST_IDS.dealFlow}>
            {flowType === EFlowType.AddTradePartner ? (
              <FormattedMessage id="constants.universalMessages.addTradePartner" />
            ) : (
              <FormattedMessage id="constants.universalMessages.createNewDeal" />
            )}
          </Typography.H5>
        </DrawerHeader>
        <FlowTransitions />
      </>
    </DrawerV2>
  );
};
