import { ComponentType, FC, ReactElement } from 'react';

import { useQuery } from 'react-query';
import { Navigate, useLocation } from 'react-router-dom';

import { useGetProfile } from '@app/api/hooks/useGetProfile';
import { getWizardInfo } from '@app/api/hooks/useGetWizardInfo';
import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { getAvailableFunctionsRequest } from '@app/api/requests/getAvailableFunctions';
import { AUTH_API_SCOPES } from '@app/core/authentication';
import { routes } from '@app/routes/config';
import { ERequestKey } from '@app/types/request';
import { CircularProgress, Stack } from '@mui/material';
import { useAuth } from '@stenngroup/auth0-sdk';
import { match } from 'ts-pattern';

export const withAutoNavigation = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return function WithAutoNavigation(props: P): ReactElement | null {
    const { pathname } = useLocation();
    const { userInfo, isAuthenticated } = useAuth();
    const { data: profile } = useGetProfile();
    const isSocialAccountNotActivated = userInfo?.userStates?.includes(
      AUTH_API_SCOPES.SOCIAL_ACCOUNT_NOT_ACTIVATED_IN_STENN
    );

    const configuredFetch = useConfiguredFetch();
    const { data: availableFunctions } = useQuery(
      [ERequestKey.GetAvailableFunctions],
      () => getAvailableFunctionsRequest({ configuredFetch }),
      {
        enabled: isAuthenticated && userInfo?.isEmailVerified && !isSocialAccountNotActivated,
        cacheTime: Infinity,
        staleTime: Infinity,
        select: (data) => (data.type === 'success' ? data.data : []),
      }
    );

    const { data: wizardInfo, isRefetching } = useQuery([ERequestKey.GetClientWizardInfo], () => getWizardInfo(), {
      enabled: isAuthenticated && userInfo?.isEmailVerified && !isSocialAccountNotActivated,
      cacheTime: Infinity,
      staleTime: Infinity,
    });

    if (!userInfo) {
      return (
        <Stack flexGrow={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      );
    }

    if (userInfo.isEmailVerified === false) {
      if (!pathname.includes(routes.auth.emailConfirmation.path)) {
        return <Navigate to={routes.auth.emailConfirmation.path} />;
      }
    }

    if (isSocialAccountNotActivated) {
      if (
        !pathname.includes(routes.auth.signUp.socialAccountActivation.path) &&
        !pathname.includes(routes.auth.signUp.accountNotCreated.path)
      ) {
        return <Navigate to={routes.auth.signUp.socialAccountActivation.path} />;
      }
    }

    if (!wizardInfo && isAuthenticated && !isRefetching && userInfo?.isEmailVerified && !isSocialAccountNotActivated) {
      return (
        <Stack flexGrow={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      );
    }

    const isIntegratorRepresentativeRole = availableFunctions?.includes('IntegratorRepresentativeBase');
    if (isIntegratorRepresentativeRole) {
      window.location.href = `${window._STENN_.PARTNERSHIPS_PORTAL_APP_URL}`;
      return null;
    }

    const isBddRole = availableFunctions?.includes('CanViewIntroducerMenu');
    if (wizardInfo?.wizardType === 'Introducer' || isBddRole) {
      window.location.replace(window._STENN_.REPS_APP_URL);
      return null;
    }

    const isOnboardingFlow = wizardInfo?.isWizardNeeded;
    const isOnboadringFinished = profile?.hasBuyerRole || profile?.hasSupplierRole;

    const isOnboardingFlowShow =
      !!isOnboardingFlow && !isOnboadringFinished && userInfo?.isEmailVerified && !isSocialAccountNotActivated;

    if (isOnboardingFlowShow && !pathname.includes(routes.roleSelection.path) && !wizardInfo?.wizardType) {
      return <Navigate to={routes.roleSelection.path} />;
    }

    if (
      isOnboardingFlowShow &&
      wizardInfo?.wizardType &&
      !pathname.includes(routes.supplierFlow.path) &&
      !pathname.includes(routes.buyerFlow.path) &&
      !pathname.includes(routes.addCompanyInformation.path)
    ) {
      return (
        <Navigate
          to={match(wizardInfo.wizardType)
            .with('Supplier', () => routes.addCompanyInformation.path)
            .with('Buyer', () => routes.addCompanyInformation.path)
            .otherwise(() => routes.roleSelection.path)}
        />
      );
    }

    return <Component {...props} />;
  };
};
