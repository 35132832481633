export interface IContactForm {
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  website: string;
  checkbox: boolean;
}

export const enum ESignatoryType {
  AUTHORISED_PERSON = 'authorisedPerson',
  ANOTHER_PERSON = 'anotherPerson',
}
