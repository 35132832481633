import { type FC, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { TStennClientsServiceApplicationPortEnumsClientsBuyerStatus } from '@app/core/__generated__';
import { TEST_IDS } from '@app/core/constants';
import { formatCurrency } from '@app/utils/formatCurrency';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormNumericField, Stack, TextField, Typography } from '@stenngroup/ui-kit';

import { getSchema } from './schema';

interface IProps {
  totalLimit: number;
  requestedLimit: number;
  status: TStennClientsServiceApplicationPortEnumsClientsBuyerStatus | undefined;
  onSubmit: (limit: number | undefined) => void;
}

const MAX_LIMIT = 999_999_999;

export const RequestLimitForm: FC<IProps> = ({ onSubmit, totalLimit, requestedLimit, status }) => {
  const isVisibleRequestNewLimit = totalLimit > 0 && ['Available', 'Overdue'].includes(status || '');
  const isVisibleRequestedLimit = requestedLimit > 0;
  const formIsRequired = !isVisibleRequestNewLimit && !isVisibleRequestedLimit;

  const [isVisibleForm, setIsVisibleForm] = useState(formIsRequired);

  const { formatMessage } = useIntl();

  const form = useForm<{ limit: number }>({
    mode: 'onSubmit',
    resolver: yupResolver(
      getSchema(formIsRequired, MAX_LIMIT, formatMessage({ id: 'createDeal.requestLimit.form.errors.limitField' }))
    ),
  });

  const handleFormSubmit = form.handleSubmit((data) => onSubmit(data.limit));

  const handleClickRequestLimitButton = (): void => setIsVisibleForm(true);

  return (
    <form id="limitForm" onSubmit={handleFormSubmit}>
      <Stack gap={1.5}>
        {isVisibleRequestNewLimit && (
          <Stack gap={1.5} data-testid={TEST_IDS.requestNewLimitForm}>
            <Typography.TextSm.SemiBold color="text.secondary">
              <FormattedMessage
                id="createDeal.requestLimit.totalLimit"
                values={{
                  value: (
                    <Typography.TextSm.SemiBold color="text.body">
                      {formatCurrency(totalLimit, {
                        currency: 'USD',
                      })}
                    </Typography.TextSm.SemiBold>
                  ),
                }}
              />
            </Typography.TextSm.SemiBold>

            {!isVisibleForm && (
              <Button
                variant="secondary"
                size="small"
                color="primary"
                fullWidth
                onClick={handleClickRequestLimitButton}
                data-testid={TEST_IDS.requestLimitButton}
              >
                <FormattedMessage id="createDeal.requestLimit" />
              </Button>
            )}
          </Stack>
        )}

        {isVisibleRequestedLimit && (
          <Stack gap={1.5} data-testid={TEST_IDS.requestedLimitText}>
            <Typography.TextSm.SemiBold color="text.secondary">
              <FormattedMessage
                id="createDeal.requestLimit.requestedLimit"
                values={{
                  value: (
                    <Typography.TextSm.SemiBold color="text.body">
                      {formatCurrency(requestedLimit, {
                        currency: 'USD',
                      })}
                    </Typography.TextSm.SemiBold>
                  ),
                }}
              />
            </Typography.TextSm.SemiBold>

            <Typography.TextSm.Regular>
              <FormattedMessage id="createDeal.requestLimit.info.hasExistingLimit" />
            </Typography.TextSm.Regular>
          </Stack>
        )}

        {isVisibleForm && (
          <Stack gap={2} data-testid={TEST_IDS.requestLimitForm}>
            <Stack gap={2} flexDirection="row">
              <Box flexBasis={120}>
                <TextField
                  name="currency"
                  value="USD"
                  label={<FormattedMessage id="constants.universalMessages.currency" />}
                  labelType="inside"
                  disabled
                  size="small"
                />
              </Box>

              <FormNumericField
                name="limit"
                control={form.control}
                label={<FormattedMessage id="createDeal.requestLimit.form.limitField" />}
                data-testid={TEST_IDS.requestLimitInput}
                allowLeadingZeros={false}
                allowNegative={false}
                decimalSeparator="."
                thousandSeparator=","
                decimalScale={2}
                min={1}
                max={MAX_LIMIT}
                suffix=""
                size="small"
              />
            </Stack>

            <Typography.TextSm.Regular>
              {isVisibleRequestNewLimit ? (
                <FormattedMessage id="createDeal.requestLimit.info.form.requestNewLimit" />
              ) : (
                <FormattedMessage id="createDeal.requestLimit.info.form" />
              )}
            </Typography.TextSm.Regular>
          </Stack>
        )}
      </Stack>
    </form>
  );
};
