import { FC } from 'react';

import { formatCurrency } from '@app/utils/formatCurrency';
import { Stack } from '@mui/material';
import { Typography } from '@stenngroup/ui-kit';

import { match } from 'ts-pattern';
import { LimitText } from './constants/limitText';
import { IRequestLimitTextProps } from './types';

export const RequestLimitText: FC<IRequestLimitTextProps> = ({ status, totalLimit, requestedLimit }) => {
  const value = match(status)
    .with('UnderReview', 'RequestingLimit', () => (requestedLimit > 0 ? requestedLimit : null))
    .otherwise(() => totalLimit);

  if (value === null) {
    return null;
  }

  return (
    <Stack gap={0.5} alignItems="center" direction="row">
      <Typography.Overline color="text.secondary">{LimitText[status]}</Typography.Overline>
      <Typography.Overline color="text.primary">
        {formatCurrency(value, {
          currency: 'USD',
        })}
      </Typography.Overline>
    </Stack>
  );
};
