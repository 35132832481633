import { useCallback, useEffect, useRef, useState } from 'react';

const getTimleft = (timeLimit: number, timeStart: number) => {
  const now = Date.now();
  // eslint-disable-next-line no-bitwise
  return Math.max((timeLimit - (now - timeStart) / 1000) | 0, 0);
};

/**
 *
 * @param timeLimit in sec
 * @param onTimeEnd
 */
export const useCountDown = (timeLimit: number, onTimeEnd?: () => void) => {
  const [counter, setCounter] = useState(timeLimit);

  const timeStart = useRef(0);

  const timer = useRef<number>();
  const frameRequest = useRef<number>();

  const clearTimer = () => {
    frameRequest.current && cancelAnimationFrame(frameRequest.current);
    timer.current && clearTimeout(timer.current);
  };

  const update = useCallback(() => {
    timer.current = window.setTimeout(() => {
      const timeLeft = getTimleft(timeLimit + 1, timeStart.current);

      if (timeLeft > 0) {
        requestAnimationFrame(update);
      }

      if (timeLeft === 0) {
        onTimeEnd && onTimeEnd();
        clearTimer();
      }

      setCounter(timeLeft);
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLimit]); // колбек onTimeEnd исключен из зависимости

  const startTimer = useCallback(() => {
    clearTimer();
    timeStart.current = Date.now();
    frameRequest.current = requestAnimationFrame(update);
  }, [update]);

  useEffect(startTimer, [startTimer]);

  const restart = useCallback(() => {
    setCounter(timeLimit);
    startTimer();
  }, [startTimer, timeLimit]);

  useEffect(() => {
    startTimer();

    return () => {
      clearTimer();
    };
  }, [startTimer]);

  return [counter, restart] as const;
};
