import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { BASE_API_URLS } from '@app/core/api';
import { HttpClientError } from '@app/core/httpClient/HttpClientError';

interface IBecomeSignerRequestPayload {
  configuredFetch: ReturnType<typeof useConfiguredFetch>;
  signer: {
    firstName: string;
    lastName: string;
    position: string;
    website: string;
    invite?: boolean;
  };
}

export const becomeSignerRequest = async ({ signer, configuredFetch }: IBecomeSignerRequestPayload) => {
  const response = await configuredFetch(`${BASE_API_URLS.onboarding}/api/v1.2/CompanySignatory/BecomeSigner`, {
    body: JSON.stringify(signer),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw await HttpClientError.fromResponse(response);
  }
};
