import { IntlFormatters } from 'react-intl';

import { REGEXP, WEBSITE_VALUE_MAX_LENGTH } from '@app/core/constants';
import * as yup from 'yup';

import { frontendErrorMessages as messages } from '@app/core/internationalization';
import { IContactForm } from '../types';

export const baseAuthorizedSignatorySchema = (formatMessage: IntlFormatters['formatMessage']) =>
  yup.object().shape<Record<keyof Omit<IContactForm, 'checkbox'>, yup.AnySchema>>({
    firstName: yup
      .string()
      .trim()
      .matches(RegExp(REGEXP.userName), formatMessage({ id: 'constants.frontendErrors.enterValidFirstName' }))
      .matches(RegExp(REGEXP.validName), formatMessage({ id: 'constants.frontendErrors.enterValidFirstName' }))
      .max(40)
      .required(formatMessage({ id: 'constants.frontendErrors.enterName' })),
    lastName: yup
      .string()
      .trim()
      .matches(RegExp(REGEXP.userName), formatMessage({ id: 'constants.frontendErrors.enterValidLastName' }))
      .matches(RegExp(REGEXP.validName), formatMessage({ id: 'constants.frontendErrors.enterValidLastName' }))
      .max(40)
      .required(formatMessage({ id: 'constants.frontendErrors.enterLastName' })),
    email: yup
      .string()
      .matches(RegExp(REGEXP.email), formatMessage({ id: 'constants.frontendErrors.enterValidEmail' }))
      .email(formatMessage({ id: 'constants.frontendErrors.enterValidEmail' }))
      .required(),
    position: yup.string().required(formatMessage({ id: 'constants.frontendErrors.enterPosition' })),
    website: yup
      .string()
      .trim(formatMessage(messages.enterValidURL))
      .matches(RegExp(REGEXP.validUrl), formatMessage(messages.enterWebsite))
      .max(WEBSITE_VALUE_MAX_LENGTH)
      .required(),
  });
