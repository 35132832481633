import { subMinutes } from 'date-fns';

export const fixDateToUTCZero = (date: Date): Date => {
  const timezoneOffset = date.getTimezoneOffset();

  if (!timezoneOffset) {
    return date;
  }

  if (date.getUTCHours() === 0) {
    return date;
  }

  return subMinutes(date, timezoneOffset);
};
