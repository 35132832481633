import { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';

import { EContractorType } from '@app/components-new/Flow/types/common';
import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { StepContainer } from '@app/components-new/StepContainer';
import { ArrowForward } from '@mui/icons-material';
import { Button, Card } from '@stenngroup/ui-kit';
import { match } from 'ts-pattern';

import { EDealTradeRole, EFlowStep } from '../../context/types';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { SignatoryForm } from './SignatoryForm';
import { addCounterPartyContactRequest } from './mutations/addCounterPartyContactRequest';
import { getSchema } from './schema';
import { IContactForm } from './types';

interface IAddTradePartnerSignatoryProps {}

export const AddTradePartnerSignatory: FC<IAddTradePartnerSignatoryProps> = () => {
  const { handleNextStep, getFlowState, getStateByStep } = useFlowTransitionsContext();
  const { counterpartyCompanyId, tradeRole } = getFlowState();

  const tradePartnerSignatoryState = getStateByStep(EFlowStep.ADD_TRADE_PARTNER_SIGNATORY);

  const isStepCompleted = !!tradePartnerSignatoryState;

  const { formatMessage } = useIntl();

  const form = useForm<IContactForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(getSchema(formatMessage)),
    defaultValues: tradePartnerSignatoryState ?? undefined,
  });

  const counterpartyTradeRole = match<EDealTradeRole | null, EContractorType | null>(tradeRole)
    .with(EDealTradeRole.Supplier, () => EContractorType.Buyer)
    .with(EDealTradeRole.Buyer, () => EContractorType.Supplier)
    .otherwise(() => null);

  const { mutate: addCounterPartyContact, isLoading: isCounterPartyContactLoading } = useMutation(
    addCounterPartyContactRequest,
    {
      onSuccess: () => {
        handleNextStep({ stepState: form.getValues() });
      },
    }
  );

  const addAuthorizedSignatory = async (requestData: IContactForm) => {
    if (!counterpartyCompanyId || !counterpartyTradeRole) {
      return;
    }

    addCounterPartyContact({
      data: {
        ...requestData,
        phoneNumber: requestData.phoneNumber,
        companyId: counterpartyCompanyId,
        companyRole: counterpartyTradeRole as unknown as EDealTradeRole,
        beSigner: true,
      },
    });
  };

  const handleFormSubmit = () => {
    if (isStepCompleted) {
      handleNextStep({ stepState: tradePartnerSignatoryState });
      return;
    }
    form.handleSubmit((data) => {
      addAuthorizedSignatory(data);
    })();
  };

  return (
    <StepContainer
      title={<FormattedMessage id="createDeal.addTradePartnerSignatory" />}
      actions={
        <Button
          variant="primary"
          endIcon={<ArrowForward />}
          disabled={isCounterPartyContactLoading}
          isLoading={isCounterPartyContactLoading}
          onClick={() => handleFormSubmit()}
        >
          <FormattedMessage id="constants.universalMessages.next" />
        </Button>
      }
    >
      <Card
        sx={(theme) => ({
          padding: 2,
          boxShadow: 'none',
          border: `1px solid ${theme.palette.grey['200']}`,
          borderRadius: theme.shape.borderRadius / 2,
        })}
      >
        <FormProvider {...form}>
          <SignatoryForm disabled={isStepCompleted} />
        </FormProvider>
      </Card>
    </StepContainer>
  );
};
