import { useMutation, useQuery } from 'react-query';

import { ERequestKey } from '@app/types/request';

import {
  getCompanySignatoryInfoView,
  removeItIsWorkingEmailAnswer,
  saveItIsWorkingEmailAnswer,
  verifyCodeAndBecomeSigner,
} from '../requests/companySignatory';

export const useGetCompanySignatoryInfoView = () =>
  useQuery(ERequestKey.GetCompanySignatoryInfoView, getCompanySignatoryInfoView, {
    cacheTime: 0,
  });

export const useRemoveItIsWorkingEmailAnswer = () => useMutation(removeItIsWorkingEmailAnswer);

export const useSaveItIsWorkingEmailAnswer = () => useMutation(saveItIsWorkingEmailAnswer);

export const useVerifyCodeAndBecomeSigner = () => useMutation(verifyCodeAndBecomeSigner);
