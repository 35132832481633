import { StepContainer } from '@app/components-new/StepContainer';
import { Card, Stack, Typography } from '@stenngroup/ui-kit';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface IFormContainerProps {
  radioGroup: ReactNode;
  actions: ReactNode;
}

export const FormContainer: FC<PropsWithChildren<IFormContainerProps>> = ({ children, radioGroup, actions }) => {
  return (
    <StepContainer title={<FormattedMessage id="createDeal.addYourSignatory" />} actions={actions}>
      <Card
        sx={(theme) => ({
          padding: 2,
          boxShadow: 'none',
          border: `1px solid ${theme.palette.grey['200']}`,
          borderRadius: theme.shape.borderRadius / 2,
        })}
      >
        <Stack gap={2}>
          <Typography.TextMd.Medium>
            <FormattedMessage id="createDeal.whoIsAuthorisedToSign" />
          </Typography.TextMd.Medium>
          <Stack gap={2}>
            {radioGroup}
            {children}
          </Stack>
        </Stack>
      </Card>
    </StepContainer>
  );
};
