import { FC } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';

import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { TEST_IDS } from '@app/core/constants/testIds';
import { contactSchema } from '@app/layouts/DashboardLayout/Header/PersonalSpace/schema';
import { editContactRequest } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/Team/EditUserDialogContent/mutations/editContactRequest';
import { IEditUserDialogContentProps } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/Team/EditUserDialogContent/types';
import { IContactForm } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/Team/types';
import { MuiPhoneData } from '@app/types/muiPhoneData';
import { ERequestKey } from '@app/types/request';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import {
  Button,
  DialogActions,
  DialogContent,
  FormPhoneField,
  FormTextField,
  Typography,
  useToast,
} from '@stenngroup/ui-kit';

export const EditUserDialogContent: FC<IEditUserDialogContentProps> = ({ onClose, contact }) => {
  const { formatMessage } = useIntl();
  const { handleSubmit, control, setValue, trigger } = useForm<IContactForm>({
    resolver: yupResolver(contactSchema(formatMessage)),
    reValidateMode: 'onChange',
    defaultValues: contact,
  });

  const queryClient = useQueryClient();
  const configuredFetch = useConfiguredFetch();
  const toast = useToast();

  const editUserMutation = useMutation(editContactRequest, {
    onSuccess: async (response, { payload }) => {
      if (response.type === 'error') {
        toast.showError(formatMessage({ id: 'components.personalSpace.editUser.failed' }));
        return;
      }
      await queryClient.refetchQueries([ERequestKey.PersonalSpaceContacts]);
      toast.showSuccess(
        formatMessage(
          {
            id: 'components.personalSpace.editUser.success',
          },
          {
            fullName: `${payload.firstName} ${payload.lastName}`,
          }
        )
      );
      onClose();
    },
  });

  const handleFormSubmit = handleSubmit(({ internalPhoneNumber: _, ...data }) => {
    editUserMutation.mutate({
      configuredFetch,
      payload: {
        ...data,
        contactId: contact.id,
      },
    });
  });
  return (
    <>
      <Stack width="100%" alignItems="center">
        <Typography.H5 color={(t) => t.palette.text.primary}>
          <FormattedMessage id="constants.universalMessages.editUser" />
        </Typography.H5>
      </Stack>
      <DialogContent
        data-testid={TEST_IDS.editUserDialogContent}
        sx={{
          width: '100%',
        }}
      >
        <Stack gap={2} width="100%">
          <FormTextField
            control={control}
            label={<FormattedMessage id="constants.universalMessages.firstName" />}
            name="firstName"
            data-testid={TEST_IDS.firstNameInput}
            size="medium"
            sx={{
              mt: 1,
            }}
          />
          <FormTextField
            control={control}
            label={<FormattedMessage id="constants.universalMessages.lastName" />}
            name="lastName"
            data-testid={TEST_IDS.lastNameInput}
            size="medium"
          />
          <FormTextField
            control={control}
            label={<FormattedMessage id="constants.universalMessages.email" />}
            name="email"
            data-testid={TEST_IDS.emailInput}
            size="medium"
          />
          <FormTextField
            control={control}
            label={<FormattedMessage id="constants.universalMessages.position" />}
            name="position"
            data-testid={TEST_IDS.positionInput}
            size="medium"
          />
          <FormPhoneField
            control={control}
            label={<FormattedMessage id="constants.universalMessages.phone" />}
            name="internalPhoneNumber"
            data-testid={TEST_IDS.phoneNumberInput}
            size="medium"
            onChange={(_: string, phoneData: MuiPhoneData) => {
              setValue('phoneNumber', phoneData.nationalNumber ? phoneData.numberValue : '');
              trigger('phoneNumber');
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          width: '100%',
          px: 3,
        }}
      >
        <Button
          sx={{
            width: '100%',
          }}
          isLoading={editUserMutation.isLoading}
          onClick={handleFormSubmit}
          data-testid={TEST_IDS.submitButton}
        >
          <FormattedMessage id="constants.universalMessages.save" />
        </Button>
      </DialogActions>
    </>
  );
};
