import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { BASE_API_URLS } from '@app/core/api';
import { HttpClientError } from '@app/core/httpClient/HttpClientError';

interface IStartVerifyCorporateContactDetailRequestPayload {
  configuredFetch: ReturnType<typeof useConfiguredFetch>;
  email: string;
}

export const startVerifyCorporateContactDetailRequest = async ({
  configuredFetch,
  email,
}: IStartVerifyCorporateContactDetailRequestPayload) => {
  const response = await configuredFetch(
    `${BASE_API_URLS.onboarding}/api/v1.2/CompanySignatory/StartVerifyCorporateContactDetail`,
    {
      method: 'POST',
      body: JSON.stringify({ emailAddress: email }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    throw await HttpClientError.fromResponse(response);
  }
};
