import { IUploadInvoiceFormOutput } from '@app/components-new/Invoice/UploadInvoiceForm/types';
import { TStennClientsServiceApplicationOperationsModelsCreateInvoiceRequestDto } from '@app/core/__generated__';
import { parseStringValue } from '@app/core/utilities/format';
import { fixDateToUTCZero } from '@app/utils/fixDateToUTCZero';

interface IFormatToPayloadParams {
  data: IUploadInvoiceFormOutput;
  tradeRelationId: string;
}

export const formatToPayload = ({
  data,
  tradeRelationId,
}: IFormatToPayloadParams): TStennClientsServiceApplicationOperationsModelsCreateInvoiceRequestDto => {
  const payload = {
    invoice: {
      number: data.number,
      dueDate: fixDateToUTCZero(data.dueDate).toISOString(),
      amount: parseStringValue(data.faceValueNationalCurrency),
      currencyIsoNumericCode: parseInt(data.currency) || 0,
      tradeRelationId,
      isCommodity: data.isCommodity,
      isPerishable: data.isPerishable,
    },
    documents: [data.invoiceDocument, data.transportDocument, ...data.otherDocuments].map((document) => ({
      documentId: document.documentId,
      documentType: document.documentType,
      documentName: document.name,
    })),
  };
  return payload as unknown as TStennClientsServiceApplicationOperationsModelsCreateInvoiceRequestDto;
};
