export enum EBuyerState {
  UnderReview = 'UnderReview',
  Available = 'Available',
  Overdue = 'Overdue',
  FinancingDenied = 'FinancingDenied',
  RequestingLimit = 'RequestingLimit',
  Unknown = 'Unknown',
}

export enum EBuyerStateFilter {
  All = 'All',
  UnderReview = 'UnderReview',
  Available = 'Available',
  Overdue = 'Overdue',
  FinancingDenied = 'FinancingDenied',
}

export enum EBuyerOrderBy {
  NameAsc = 'NameAsc',
  NameDesc = 'NameDesc',
}

export interface IBuyerCompact {
  id: string;
  myCompanyName: string;
  myCompanyId: string;
  myCompanyIdentityId: string;
  counterpartyCompanyName: string;
  counterpartyCompanyId: string;
  counterpartyCompanyIdentityId: string;
  tradeRelationId: string;
  status: EBuyerState;
  totalLimit: number;
  usedLimit: number;
  reservedLimit: number;
  requestedLimit: number;
  availableLimit: number;
  overdue: number;
}

export interface IBuyersList {
  companies: IBuyerCompact[];
  count: number;
}
