import { FC, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

import { useGetProfile } from '@app/api/hooks/useGetProfile';
import { useHttpClient } from '@app/api/lib/useHttpClient';
import { CompanyStatus } from '@app/components-new/CompanyStatus';
import { FinanceGraph } from '@app/components-new/FinanceGraph';
import { FinanceStats } from '@app/components-new/FinanceStats';
import { RequestLimitText } from '@app/components-new/RequestLimitText';
import { TStennClientsServiceApplicationPortModelsBuyerBuyerStatusLimitsResponseDto } from '@app/core/__generated__';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { TEST_IDS } from '@app/core/constants';
import { showBuyerFinanceData } from '@app/helpers/showBuyerFinanceData';
import { AddUserDialogContent } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/AddUserDialogContent';
import { CompanyInformation } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/CompanyInformation';
import { Team } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/Team';
import { IPersonalSpaceProps } from '@app/layouts/DashboardLayout/Header/PersonalSpace/types';
import { ESupplierState } from '@app/pages/Suppliers/SuppliersPage/types';
import { Divider, Stack } from '@mui/material';
import { Button, Dialog, Tabs, Typography } from '@stenngroup/ui-kit';

const TABS = [
  {
    label: <FormattedMessage id="constants.universalMessages.team" />,
    value: 'team',
  },
  {
    label: <FormattedMessage id="constants.universalMessages.companyInformation" />,
    value: 'companyInformation',
  },
] as const;

type TTabValue = (typeof TABS)[number]['value'];

const ComponentMap: Record<TTabValue, FC> = {
  team: () => <Team />,
  companyInformation: () => <CompanyInformation />,
};

export const PersonalSpace: FC<IPersonalSpaceProps> = () => {
  const [currentTab, setCurrentTab] = useState<TTabValue>('team');
  const { data: profile } = useGetProfile();

  const httpClient = useHttpClient();
  const { data: financeData, status: loadingState } =
    useQuery<TStennClientsServiceApplicationPortModelsBuyerBuyerStatusLimitsResponseDto>(
      [apiRoutes.BuyerGetBuyerStatusLimits, httpClient],
      () => httpClient(apiRoutes.BuyerGetBuyerStatusLimits, 'get', {}),
      {
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled: !!profile,
      }
    );

  const handleChange = (_e: React.SyntheticEvent<Element, Event>, newValue: TTabValue): void => {
    setCurrentTab(newValue);
  };

  const Component = ComponentMap[currentTab];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const supplierStatus = profile?.companyInfo?.clientsSupplierStatus ?? ESupplierState.Unknown;

  return (
    <>
      <Stack gap={2} py={1} data-testid={TEST_IDS.personalSpace}>
        <Stack gap={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack gap={1}>
              <Typography.Body1SemiBold data-testid={TEST_IDS.personalSpaceCompanyName}>
                {profile?.companyInfo?.companyName}
              </Typography.Body1SemiBold>
              {financeData ? (
                <Stack direction="row" gap={1.5} alignItems="center">
                  <CompanyStatus status={financeData.clientsBuyerStatus} />
                  <Stack alignItems="center" direction="row" gap={0.5} data-testid={TEST_IDS.requestedLimit}>
                    <RequestLimitText
                      status={financeData.clientsBuyerStatus}
                      totalLimit={financeData.totalLimit}
                      requestedLimit={financeData.requestedLimit}
                    />
                  </Stack>
                </Stack>
              ) : (
                <CompanyStatus status={supplierStatus} />
              )}
            </Stack>
            <Stack>
              <Button
                size="small"
                variant="tertiary"
                color="primary"
                onClick={() => setIsModalOpen(true)}
                data-testid={TEST_IDS.personalSpaceAddUserButton}
              >
                <FormattedMessage id="constants.universalMessages.addUser" />
              </Button>
            </Stack>
          </Stack>
          {financeData && showBuyerFinanceData(financeData.clientsBuyerStatus) && (
            <Stack gap={1.5} data-testid={TEST_IDS.financeData}>
              <FinanceGraph
                loadingState={loadingState}
                used={financeData.usedLimit}
                available={financeData.totalLimit - financeData.usedLimit - financeData.reservedLimit}
                reserved={financeData.reservedLimit}
                overdue={financeData.overdue}
              />
              <FinanceStats
                loadingState={loadingState}
                used={financeData.usedLimit}
                available={financeData.totalLimit - financeData.usedLimit - financeData.reservedLimit}
                reserved={financeData.reservedLimit}
                overdue={financeData.overdue}
                currencyCode="USD"
              />
            </Stack>
          )}
        </Stack>
        <Divider />
        <Stack gap={2}>
          <Tabs tabs={TABS} value={currentTab} onChange={handleChange} />
          <Stack>
            <Component />
          </Stack>
        </Stack>
      </Stack>
      <Dialog
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        fullWidth
        size="medium"
        data-testid={TEST_IDS.personalSpaceAddUserDialog}
      >
        <AddUserDialogContent onClose={() => setIsModalOpen(false)} />
      </Dialog>
    </>
  );
};
