import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

import { CheckCircle } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { Button, Card, Typography } from '@stenngroup/ui-kit';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { EDealTradeRole } from '@app/components-new/FlowTransitions/context/types';
import { StepContainer } from '@app/components-new/StepContainer';
import { TextSkeleton } from '@app/components-new/TextSkeleton';
import { calculateTypographyHeight } from '@app/components-new/TextSkeleton/helpers/calculateTypographyHeight';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { TEST_IDS } from '@app/core/constants';
import { routes } from '@app/routes/config';
import { useNavigate } from 'react-router-dom';

interface IDealSubmittedProps {}

export const DealSubmitted: FC<IDealSubmittedProps> = () => {
  const { close, getFlowState } = useFlowTransitionsContext();
  const { counterpartyCompanyId, tradeRole } = getFlowState();
  const theme = useTheme();
  const navigate = useNavigate();

  const targetRequest = tradeRole === EDealTradeRole.Supplier ? apiRoutes.BuyerGetBuyer : apiRoutes.SupplierGetSupplier;
  const httpClient = useHttpClient();
  const { data } = useQuery({
    queryKey: [targetRequest, counterpartyCompanyId],
    queryFn: () => {
      return httpClient(targetRequest, 'post', {
        data: {
          id: counterpartyCompanyId!,
        },
      });
    },
  });

  const onViewDealsPageClick = useCallback(() => {
    close();
    navigate(tradeRole === EDealTradeRole.Buyer ? routes.deals.dealsAsBuyer.path : routes.deals.path);
  }, [close, navigate]);

  return (
    <StepContainer
      dataTestId={TEST_IDS.dealSubmitted}
      title={<FormattedMessage id="createDeal.dealSubmitted" />}
      canGoBack={false}
      showActions={false}
    >
      <Card
        sx={(theme) => ({
          padding: 2,
          boxShadow: 'none',
          border: `1px solid ${theme.palette.grey['200']}`,
          borderRadius: theme.shape.borderRadius / 2,
        })}
      >
        <Stack gap={2} textAlign="center" alignItems="center">
          <CheckCircle
            fontSize="large"
            style={{
              fontSize: 80,
            }}
            color="success"
          />
          <Typography.TextMd.SemiBold color="text.primary">
            <FormattedMessage
              id="createDeal.dealSubmitted.description1"
              values={{
                Name: <span data-testid={TEST_IDS.companyName}>{data?.counterpartyCompanyName}</span> ?? (
                  <TextSkeleton
                    data-testid={TEST_IDS.companyNameLoading}
                    status="loading"
                    style={{
                      height: calculateTypographyHeight(theme.typography.body1),
                      width: 100,
                    }}
                  />
                ),
              }}
            />
          </Typography.TextMd.SemiBold>
          <Typography.TextMd.Regular color="text.primary">
            <FormattedMessage id="createDeal.dealSubmitted.description2" />
          </Typography.TextMd.Regular>
          <Typography.TextMd.Regular color="text.primary">
            <FormattedMessage id="createDeal.dealSubmitted.description3" />
          </Typography.TextMd.Regular>
          <Button variant="tertiary" size="small" onClick={onViewDealsPageClick} data-testid={TEST_IDS.viewDealsButton}>
            <FormattedMessage id="createDeal.dealSubmitted.viewDealsPage" />
          </Button>
        </Stack>
      </Card>
    </StepContainer>
  );
};
