import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { IContactForm } from '@app/components-new/FlowTransitions/screens/AddCompanySignatory/types';
import { BASE_API_URLS } from '@app/core/api/constants';
import { HttpClientError } from '@app/core/httpClient/HttpClientError';

interface IVerifyCodeAndBecomeSignerRequestPayload {
  configuredFetch: ReturnType<typeof useConfiguredFetch>;
  email: string;
  code: string;
  contact: IContactForm;
}

export const verifyCodeAndBecomeSignerRequest = async ({
  configuredFetch,
  email,
  code,
  contact,
}: IVerifyCodeAndBecomeSignerRequestPayload) => {
  const response = await configuredFetch(
    `${BASE_API_URLS.onboarding}/api/v1.2/CompanySignatory/VerifyCodeAndBecomeSigner`,
    {
      method: 'POST',
      body: JSON.stringify({ emailAddress: email, otpCode: code, ...contact }),
      headers: { 'Content-Type': 'application/json' },
    }
  );

  if (!response.ok) {
    throw await HttpClientError.fromResponse(response);
  }
  const body = await response.json();
  if (body.isSuccess) {
    return body;
  }
  throw new HttpClientError(response, body?.error ?? {});
};
