import { ApiProvider, BASE_API_URLS } from '@app/core/api';

interface ICompanySignatoryInfoView {
  infoAboutMe: {
    firstName: string;
    lastName: string;
    position: string;
    corporateEmail: {
      emailAddress: string;
      emailVerified: true;
    };
  };
  anotherPerson: {
    firstName: string;
    lastName: string;
    position: string;
    corporateEmail: {
      emailAddress: string;
      emailVerified: true;
    };
  };
  personalEmailAsWorkEmail: true;
  passedFlow: any;
  companyInfo: {
    website: string;
  };
  isSuccess: boolean;
  error: {
    message: string;
    strCode: string;
  };
}
interface ISignerInfo {
  firstName: string;
  lastName: string;
  position: string;
  website: string;
  invite?: boolean;
}
interface ISignerInfoWithEmail extends ISignerInfo {
  emailAddress: string;
}
interface ISignerInfoWithEmailAndCode extends ISignerInfoWithEmail {
  otpCode: string;
}

export const getCompanySignatoryInfoView = () =>
  ApiProvider.apiRequest<ICompanySignatoryInfoView>(
    `${BASE_API_URLS.onboarding}/api/v1.2/CompanySignatory/GetCompanySignatoryInfoView`,
    { method: 'GET' }
  );

export const inviteNewContactAndMakeSigner = async (body: ISignerInfoWithEmail) => {
  return ApiProvider.apiRequest<ICompanySignatoryInfoView>(
    `${BASE_API_URLS.onboarding}/api/v1.2/CompanySignatory/InviteNewContactAndMakeSigner`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    }
  );
};
export const removeItIsWorkingEmailAnswer = () =>
  ApiProvider.apiQueryRequest<ICompanySignatoryInfoView>(
    `${BASE_API_URLS.onboarding}/api/v1.2/CompanySignatory/RemoveItIsWorkingEmailAnswer`,
    { method: 'POST' }
  );

export const saveItIsWorkingEmailAnswer = () =>
  ApiProvider.apiRequest<ICompanySignatoryInfoView>(
    `${BASE_API_URLS.onboarding}/api/v1.2/CompanySignatory/SaveItIsWorkingEmailAnswer`,
    { method: 'POST' }
  );

export const startVerifyCorporateContactDetailRequest = async (emailAddress: string) => {
  return ApiProvider.apiRequest<ICompanySignatoryInfoView>(
    `${BASE_API_URLS.onboarding}/api/v1.2/CompanySignatory/StartVerifyCorporateContactDetail`,
    { method: 'POST', body: JSON.stringify({ emailAddress }) }
  );
};
export const verifyCodeAndBecomeSigner = (body: ISignerInfoWithEmailAndCode) =>
  ApiProvider.apiRequest<ICompanySignatoryInfoView>(
    `${BASE_API_URLS.onboarding}/api/v1.2/CompanySignatory/VerifyCodeAndBecomeSigner`,
    { method: 'POST', body: JSON.stringify(body) }
  );

export const becomeSignerRequest = async (signer: ISignerInfo) => {
  return ApiProvider.apiRequest<ISignerInfo>(`${BASE_API_URLS.onboarding}/api/v1.2/CompanySignatory/BecomeSigner`, {
    method: 'POST',
    body: JSON.stringify(signer),
  });
};

export const isPublicDomainEmail = async (email: string) => {
  return ApiProvider.apiRequest<boolean>(
    `${BASE_API_URLS.onboarding}/api/v1.2/EmailValidation/IsEmailInPublicDomain/${email}`,
    {
      method: 'GET',
    }
  );
};
