import { useGetProfile } from '@app/api/hooks/useGetProfile';
import { useHttpClient } from '@app/api/lib/useHttpClient';
import { EDealTradeRole, IFlowState } from '@app/components-new/FlowTransitions/context/types';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { assert } from '@app/helpers/assert';
import { useMutation } from 'react-query';

export const useInitialFlowState = () => {
  const httpClient = useHttpClient();
  const { data: profile } = useGetProfile();

  const mutation = useMutation((id: string) => {
    return httpClient(apiRoutes.CounterpartyGetCounterpartyInfo, 'post', {
      query: {
        counterpartyId: id,
      },
    });
  });

  const retrieveInitialFlowState = async (
    params: Partial<IFlowState> & { tradeRole: EDealTradeRole | null }
  ): Promise<IFlowState> => {
    assert(profile, 'profile is required');
    assert(params.counterpartyCompanyId, 'counterpartyCompanyId is required');

    const data = await mutation.mutateAsync(params.counterpartyCompanyId);
    const supplierHasBankDetails =
      params.tradeRole === EDealTradeRole.Supplier ? profile.companyInfo?.companyHasBankDetails : data.hasBankAccount;
    return {
      ...params,
      myCompanyHasSigner: profile.companyInfo?.companyHasSigner,
      counterpartyCompanyHasSigner: data.hasCounterpartySigner,
      supplierHasBankDetails,
      hasDraftInvoice: data.hasDraftInvoice,
    } as IFlowState;
  };

  return { retrieveInitialFlowState, isLoading: mutation.isLoading };
};
